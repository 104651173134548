import React from 'react'
// import underConstructionImage from './under-construction.png'

const SearchRecipe = (errorMessage) => {
    return (

        <div className='search-coming-soon'>
            <h2>Under Construction</h2>
            <br></br>
            <img src="under-construction.png" alt="Under Construction Image"></img>
            <h2>Search Function Coming Christmas 2021!</h2>
        </div>
    )
}

export default SearchRecipe